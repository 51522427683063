import Cookies from 'js-cookie';

export default class CopilotCloud9Validate {
  static async get(filters) {
    const response = await fetch(
      `${process.env.REACT_APP_COPILOT_CLOUD9}/validate?${new URLSearchParams(
        filters
      ).toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + Cookies.get('cognito-jwt'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const data = await response.json();
    return { status: response.status, data };
  }
}

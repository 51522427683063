import { React, useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableHead,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  TextField,
  Alert,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from '@mui/material';
import { PlusIcon, EditIcon, DeleteIcon, ValidateIcon } from '../theme/icons';
import { fireDialog } from '../components/Dialog';
import HookForm from '../components/HookForm';
import { LoadingButton } from '@mui/lab';
import { useCalendarsEdit } from '../services/calendars/mutations';
import {
  useCloud9CalendarCreate,
  useCloud9CalendarDelete,
} from '../services/cloud9Calendars/mutations';
import { useCloud9Locations } from '../services/cloud9Locations/queries';
import { useCloud9Staff } from '../services/cloud9Staff/queries';
import { useCloud9ScheduleViews } from '../services/Cloud9ScheduleView/queries';
import { useCloud9AppointmnetTypes } from '../services/cloud9AppointmentTypes/queries';
import { useCloud9Relationship } from '../services/cloud9Relationship/queries';
import { useCloud9ContactinfoTypes } from '../services/cloud9ContactinfoTypes/queries';
import { useOrg } from '../../contexts/OrgProvider';
import { useQueryClient } from 'react-query';
import { calendarsQueryKeys } from '../services/calendars/queries';
import CopilotCloud9Validate from '../services/copilotcloud9Validate/CopilotCloud9Validate';

export default function Cloud9SettingsPageCalendar({ calendarData }) {
  const { id: orgId } = useOrg();

  const onCreate = () => {
    fireDialog((promiseProps) => AddCloud9Calendar({ orgId, ...promiseProps }));
  };
  const onEditClick = (calendarData) => {
    fireDialog((promiseProps) =>
      EditCloud9Calendar({ calendarData, ...promiseProps })
    );
  };
  const onDeleteClick = (calendarId) => {
    fireDialog((promiseProps) =>
      DeleteCloud9Calendar({ calendarId, ...promiseProps })
    );
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [SucessMessage, setSucessMessage] = useState('');
  const [ErrorMessage, setErrorMessage] = useState('');

  const onValidateClick = (calendarId) => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    const filters = { calendar_id: calendarId };

    CopilotCloud9Validate.get(filters)
      .then(({ status, data }) => {
        if (status === 200) {
          const successMessage = data?.message;
          setSucessMessage(successMessage);
          setIsSuccess(true);
        } else if (status === 400) {
          const errorMessage = data?.error;
          setErrorMessage(errorMessage);
          setIsError(true);
        }
      })
      .catch((error) => {
        setErrorMessage('An unexpected error occurred. Please try again.');
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isSuccess || isError) {
      const timer = setTimeout(() => {
        setIsError(false);
        setIsSuccess(false);
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, [isSuccess, isError]);

  return (
    <Box sx={{ mt: 2 }}>
      {isLoading ? (
        <CircularProgress size={25} />
      ) : isError ? (
        <Alert
          severity="error"
          style={{ marginTop: '10px' }}
          onClose={() => setIsError(false)}
        >
          {ErrorMessage}
        </Alert>
      ) : isSuccess ? (
        <Alert
          severity="success"
          style={{ marginTop: '10px' }}
          onClose={() => setIsSuccess(false)}
        >
          {SucessMessage}
        </Alert>
      ) : null}

      <TableContainer sx={{ mt: 1, mb: 2 }}>
        <Table style={{ width: 500 }}>
          <TableHead>
            <TableRow size="small">
              <TableCell align="left">Name</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calendarData?.items.map((calData) => (
              <TableRow key={calData.id}>
                <TableCell align="left">
                  <Typography component={'span'} variant="subtitle3">
                    {calData.name}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Button
                    startIcon={<EditIcon />}
                    size="small"
                    variant="text"
                    color="primary"
                    sx={{ marginRight: 1 }}
                    onClick={() => onEditClick(calData)}
                    disabled={isLoading}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    size="small"
                    variant="text"
                    color="error"
                    sx={{ marginRight: 1 }}
                    onClick={() => onDeleteClick(calData.id)}
                    disabled={isLoading}
                  >
                    Delete
                  </Button>
                  <Button
                    startIcon={<ValidateIcon />}
                    size="small"
                    variant="text"
                    color="primary"
                    sx={{ marginRight: 1 }}
                    onClick={() => onValidateClick(calData.id)}
                    disabled={isLoading}
                  >
                    Validate
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        startIcon={<PlusIcon />}
        onClick={() => onCreate()}
        sx={{ marginRight: 1 }}
        disabled={isLoading}
      >
        Add New
      </Button>
    </Box>
  );
}

const AddCloud9Calendar = ({ orgId, isOpen, onReject, onResolve }) => {
  const {
    mutate: cloud9subscribe,
    isError,
    isLoading,
    isSuccess,
  } = useCloud9CalendarCreate();

  const [calendarName, setCalendarName] = useState();
  const [cloud9ClientID, setCloud9ClientID] = useState();
  const [cloud9URL, setCloud9URL] = useState();
  const [Cloud9username, setCloud9Username] = useState();
  const [Cloud9password, setCloud9Password] = useState();
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [isErrorVisible, setErrorVisible] = useState(false);
  const queryClient = useQueryClient();

  const defaultValues = {
    name: '',
    timeZone: '',
    cloud9_client_id: '',
    cloud9_url: '',
    cloud9_username: '',
    cloud9_password: '',
  };

  const useFormProps = {
    defaultValues,
  };

  useEffect(() => {
    if (isSuccess) {
      // Success case
      setTimeout(() => {
        setAlertVisible(false);
        onResolve();
      }, 1000);
    } else if (isError) {
      // Error case
      setTimeout(() => {
        setErrorVisible(false);
        onResolve();
      }, 1000);
    }
  }, [isSuccess, isError]);

  const possibleTimezones = [
    'Pacific/Honolulu',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Puerto_Rico',
  ];

  const handleCreateSubmit = (formData) => {
    cloud9subscribe(
      {
        cloud9_username: Cloud9username,
        cloud9_password: Cloud9password,
        cloud9_service_url: cloud9URL,
        organization_id: orgId,
        name: calendarName,
        timezone: formData?.timeZone,
        cloud9_client_id: cloud9ClientID,
      },
      {
        onSuccess: () => {
          setAlertVisible(true);
          queryClient.invalidateQueries(calendarsQueryKeys.lists());
        },
      },
      {
        onError: () => {
          setErrorVisible(true);
        },
      }
    );
  };

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Create Cloud9 Calendar</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isErrorVisible && (
              <Alert severity="error">
                There was a problem while creating calendar.
              </Alert>
            )}
            {isAlertVisible && (
              <Alert severity="success">Calendar created successfully.</Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                required
                defaultValue={calendarName || ''}
                onChange={(e) => setCalendarName(e.target.value)}
              />
              <HookForm.Select name="timeZone" label="Time Zone" required>
                {possibleTimezones.map((zone) => (
                  <MenuItem value={zone} key={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </HookForm.Select>
              <TextField
                label="Username"
                type="text"
                required
                fullWidth
                value={Cloud9username || ''}
                onChange={(e) => setCloud9Username(e.target.value)}
              />
              <TextField
                label="Password"
                type="password"
                required
                fullWidth
                value={Cloud9password || ''}
                onChange={(e) => setCloud9Password(e.target.value)}
              />
              <TextField
                label="Cloud9 URL"
                type="text"
                required
                defaultValue={cloud9URL || ''}
                onChange={(e) => setCloud9URL(e.target.value)}
              />
              <TextField
                label="Client ID"
                type="text"
                required
                defaultValue={cloud9ClientID || ''}
                onChange={(e) => setCloud9ClientID(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const EditCloud9Calendar = ({ calendarData, isOpen, onReject, onResolve }) => {
  const [shouldFetch, setShouldFetch] = useState(true);

  const { mutate: calendarEdit, isLoading, isError, isSuccess } = useCalendarsEdit();

  const [calendarName, setupdatedCalendarName] = useState();
  const [cloud9URL, setCloud9URL] = useState();
  const [cloud9clientId, setCloud9ClientId] = useState();
  const [cloud9location, setCloud9Location] = useState();
  const [cloud9provider, setCloud9Provider] = useState();
  const [cloud9scheduleview, setCloud9ScheduleView] = useState([]);
  const [cloud9appointmenttype, setCloud9AppointmentType] = useState();
  const [cloud9appointmenttypeAge12, setCloud9AppointmentTypeAge12] = useState();
  const [cloud9appointmenttypeAge18, setCloud9AppointmentTypeAge18] = useState();
  const [cloud9EmailinfoType, setcloud9EmailinfoType] = useState();
  const [cloud9PhoneinfoType, setcloud9PhoneinfoType] = useState();
  const [daysOut, setDaysOut] = useState();
  const [clou9Relationships, setCloud9Relationships] = useState();

  const defaultValues = {
    name: calendarData.name || '',
    timeZone: calendarData.timezone || '',
    cloud_url: cloud9URL,
    cloud9_client_id: cloud9clientId,
    cloud9_location_guid: cloud9location,
    cloud9_provider_guid: cloud9provider,
    cloud9_schedule_view_guid: cloud9scheduleview,
    cloud9_appointment_type_guid: cloud9appointmenttype,
    cloud9_appointment_type_guid_12: cloud9appointmenttypeAge12,
    cloud9_appointment_type_guid_18: cloud9appointmenttypeAge18,
    cloud9_relationship_type_guid: clou9Relationships,
    cloud9_email_contact_info: cloud9EmailinfoType,
    cloud9_phone_contact_info: cloud9PhoneinfoType,
  };

  const useFormProps = {
    defaultValues,
  };

  useEffect(() => {
    const setDefaultValue = (itemName, setterFunction) => {
      if (calendarData?.config_items) {
        const item = calendarData?.config_items?.find(
          (item) => item?.name === itemName
        );
        if (setterFunction === setCloud9ScheduleView) {
          setterFunction(item?.value ? item?.value.split(',') : []);
        } else {
          setterFunction(item?.value);
        }
      }
    };

    setDefaultValue('days_out', setDaysOut);
    setDefaultValue('cloud9_url', setCloud9URL);
    setDefaultValue('cloud9_client_id', setCloud9ClientId);
    setDefaultValue('cloud9_location_guid', setCloud9Location);
    setDefaultValue('cloud9_provider_guid', setCloud9Provider);
    setDefaultValue('cloud9_schedule_view_guid', setCloud9ScheduleView);
    setDefaultValue('cloud9_appointment_type_guid', setCloud9AppointmentType);
    setDefaultValue('cloud9_email_contact_info', setcloud9EmailinfoType);
    setDefaultValue('cloud9_phone_contact_info', setcloud9PhoneinfoType);
    setDefaultValue(
      'cloud9_appointment_type_guid_12',
      setCloud9AppointmentTypeAge12
    );
    setDefaultValue(
      'cloud9_appointment_type_guid_18',
      setCloud9AppointmentTypeAge18
    );
    setDefaultValue('cloud9_relationship_type_guid', setCloud9Relationships);
  }, [calendarData]);

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  const possibleTimezones = [
    'Pacific/Honolulu',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Puerto_Rico',
  ];

  const { data: cloud9locations, isError: locationError } = useCloud9Locations(
    {
      calendar_id: calendarData.id,
    },
    { enabled: shouldFetch }
  );

  const { data: cloud9Staff, isError: staffError } = useCloud9Staff({
    calendar_id: calendarData.id,
  });
  const {
    data: cloud9ScheduleViews,
    isError: schduleViewError,
  } = useCloud9ScheduleViews(
    {
      calendar_id: calendarData.id,
    },
    { enabled: shouldFetch }
  );
  const {
    data: cloud9Appointmenttypes,
    isError: appointmenttypesError,
  } = useCloud9AppointmnetTypes(
    {
      calendar_id: calendarData.id,
    },
    { enabled: shouldFetch }
  );
  const {
    data: cloud9Relationship,
    isError: relationshipError,
  } = useCloud9Relationship(
    {
      calendar_id: calendarData.id,
    },
    { enabled: shouldFetch }
  );

  const {
    data: cloudContactinfoTypes,
    isError: contactinfoTypesError,
  } = useCloud9ContactinfoTypes(
    {
      calendar_id: calendarData.id,
    },
    { enabled: shouldFetch }
  );

  const handleEditSubmit = (formData) => {
    let convert_list_str_scheduleview = '';

    if (cloud9scheduleview.length > 0) {
      convert_list_str_scheduleview = cloud9scheduleview
        .map((item) => item.trim())
        .join(',');
    }
    calendarEdit({
      id: calendarData.id,
      name: calendarName || calendarData.name,
      timezone: formData.timeZone,
      config_items: [
        {
          name: 'days_out',
          value: daysOut,
        },
        {
          name: 'cloud9_url',
          value: cloud9URL,
        },
        {
          name: 'cloud9_client_id',
          value: cloud9clientId,
        },
        {
          name: 'cloud9_location_guid',
          value: cloud9location,
        },
        {
          name: 'cloud9_provider_guid',
          value: cloud9provider,
        },
        {
          name: 'cloud9_schedule_view_guid',
          value: convert_list_str_scheduleview,
        },
        {
          name: 'cloud9_appointment_type_guid',
          value: cloud9appointmenttype,
        },
        {
          name: 'cloud9_appointment_type_guid_12',
          value: cloud9appointmenttypeAge12,
        },
        {
          name: 'cloud9_appointment_type_guid_18',
          value: cloud9appointmenttypeAge18,
        },
        {
          name: 'cloud9_relationship_type_guid',
          value: clou9Relationships,
        },
        {
          name: 'cloud9_email_contact_info',
          value: cloud9EmailinfoType,
        },
        {
          name: 'cloud9_phone_contact_info',
          value: cloud9PhoneinfoType,
        },
      ],
    });
  };

  useEffect(() => {
    if (
      locationError &&
      appointmenttypesError &&
      staffError &&
      schduleViewError &&
      relationshipError
    ) {
      setShouldFetch(false); // Prevent future fetch attempts
    }
  }, [
    locationError,
    appointmenttypesError,
    staffError,
    schduleViewError,
    relationshipError,
  ]);

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Edit Cloud9 Calendar</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleEditSubmit}>
          <DialogContent>
            {locationError &&
              appointmenttypesError &&
              staffError &&
              schduleViewError &&
              relationshipError && <Alert severity="error">API call fails.</Alert>}
            {isError && (
              <Alert severity="error">
                There was a problem while updating calendar.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">Calendar updated successfully.</Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <TextField
                label="Name"
                type="text"
                required
                fullWidth
                defaultValue={calendarData.name || ''}
                onChange={(e) => setupdatedCalendarName(e.target.value)}
              />
              <HookForm.Select name="timeZone" label="Time Zone" required>
                {possibleTimezones.map((zone) => (
                  <MenuItem value={zone} key={zone}>
                    {zone}
                  </MenuItem>
                ))}
              </HookForm.Select>
              <TextField
                label="Days Out"
                type="number"
                required
                fullWidth
                defaultValue={daysOut || ''}
                onChange={(e) => setDaysOut(e.target.value)}
              />
              <TextField
                label="Cloud9 URL"
                type="text"
                required
                defaultValue={cloud9URL || ''}
                onChange={(e) => setCloud9URL(e.target.value)}
              />
              <TextField
                label="Client ID"
                type="texts"
                required
                value={cloud9clientId}
                onChange={(e) => setCloud9ClientId(e.target.value)}
              />
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="location-guid">Location</InputLabel>
                <Select
                  label="Location"
                  required
                  value={`${cloud9location}`}
                  onChange={(e) =>
                    setCloud9Location(e.target.value, 'cloud9_location_guid')
                  }
                >
                  {!cloud9locations && !locationError ? (
                    <MenuItem>
                      <CircularProgress size={25} />
                    </MenuItem>
                  ) : locationError ? null : Array.isArray(cloud9locations) ? (
                    cloud9locations.map((location) => (
                      <MenuItem
                        value={location?.LocationGUID}
                        key={location?.LocationGUID}
                      >
                        {location?.LocationName}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem
                      value={cloud9locations?.LocationGUID}
                      key={cloud9locations?.LocationGUID}
                    >
                      {cloud9locations?.LocationName}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="provider-guid">Provider</InputLabel>
                <Select
                  label="Provider"
                  required
                  value={`${cloud9provider}`}
                  onChange={(e) =>
                    setCloud9Provider(e.target.value, 'cloud9_provider_guid')
                  }
                >
                  {!cloud9Staff && !staffError ? (
                    <MenuItem>
                      <CircularProgress size={25} />
                    </MenuItem>
                  ) : staffError ? null : (
                    cloud9Staff.map((staff) => (
                      <MenuItem value={staff.EmployeeGUID} key={staff.EmployeeType}>
                        {staff.EmployeeTitle +
                          ' ' +
                          staff.EmployeeFirstName +
                          ' ' +
                          staff.EmployeeLastName +
                          '-' +
                          staff.EmployeeType}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="schedule-column-guid">Schedule View</InputLabel>
                <Select
                  multiple
                  label="Schedule View"
                  value={cloud9scheduleview}
                  name="schedule_view_guid"
                  required
                  onChange={(e) =>
                    setCloud9ScheduleView(
                      e.target.value,
                      'cloud9_schedule_view_guid'
                    )
                  }
                >
                  {!cloud9ScheduleViews && !schduleViewError ? (
                    <MenuItem>
                      <CircularProgress size={25} />
                    </MenuItem>
                  ) : schduleViewError ? null : (
                    cloud9ScheduleViews?.map((views) => (
                      <MenuItem
                        value={views.schdvwGUID}
                        key={views.ScheduleViewDescription}
                      >
                        {views.ScheduleViewDescription}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="appointment-type">Appointment Type</InputLabel>
                <Select
                  label="Appointment Type"
                  value={`${cloud9appointmenttype}`}
                  required
                  onChange={(e) =>
                    setCloud9AppointmentType(
                      e.target.value,
                      'cloud9_appointment_type_guid'
                    )
                  }
                >
                  {!cloud9Appointmenttypes && !appointmenttypesError ? (
                    <MenuItem>
                      <CircularProgress size={25} />
                    </MenuItem>
                  ) : appointmenttypesError ? null : (
                    cloud9Appointmenttypes.map((appointment) => (
                      <MenuItem
                        value={appointment.AppointmentTypeGUID}
                        key={appointment.AppointmentTypeDescription}
                        disabled={
                          appointment?.AppointmentTypeAllowOnlineScheduling !==
                          'True'
                        }
                      >
                        {appointment.AppointmentTypeDescription}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="appointment-type-12-18">
                  Appointment Type for Adolescents
                </InputLabel>
                <Select
                  label="Appointment Type for Adolescents"
                  required
                  value={`${cloud9appointmenttypeAge18}`}
                  onChange={(e) =>
                    setCloud9AppointmentTypeAge18(
                      e.target.value,
                      'cloud9_appointment_type_guid_18'
                    )
                  }
                >
                  {!cloud9Appointmenttypes && !appointmenttypesError ? (
                    <MenuItem>
                      <CircularProgress size={25} />
                    </MenuItem>
                  ) : appointmenttypesError ? null : (
                    cloud9Appointmenttypes.map((appointment) => (
                      <MenuItem
                        value={appointment.AppointmentTypeGUID}
                        key={appointment.AppointmentTypeDescription}
                        disabled={
                          appointment?.AppointmentTypeAllowOnlineScheduling !==
                          'True'
                        }
                      >
                        {appointment.AppointmentTypeDescription}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="appointment-type-child">
                  Appointment Type Child
                </InputLabel>
                <Select
                  label="Appointment Type Child"
                  required
                  value={`${cloud9appointmenttypeAge12}`}
                  onChange={(e) =>
                    setCloud9AppointmentTypeAge12(
                      e.target.value,
                      'cloud9_appointment_type_guid_12'
                    )
                  }
                >
                  {!cloud9Appointmenttypes && !appointmenttypesError ? (
                    <MenuItem>
                      <CircularProgress size={25} />
                    </MenuItem>
                  ) : appointmenttypesError ? null : (
                    cloud9Appointmenttypes.map((appointment) => (
                      <MenuItem
                        value={appointment.AppointmentTypeGUID}
                        key={appointment.AppointmentTypeDescription}
                        disabled={
                          appointment?.AppointmentTypeAllowOnlineScheduling !==
                          'True'
                        }
                      >
                        {appointment.AppointmentTypeDescription}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="responsible-party-relationship-guid">
                  Responsible Party Relationship
                </InputLabel>
                <Select
                  label="Responsible Party Relationship"
                  required
                  value={`${clou9Relationships}`}
                  onChange={(e) =>
                    setCloud9Relationships(
                      e.target.value,
                      'cloud9_relationship_type_guid'
                    )
                  }
                >
                  {!cloud9Relationship && !relationshipError ? (
                    <MenuItem>
                      <CircularProgress size={25} />
                    </MenuItem>
                  ) : relationshipError ? null : (
                    cloud9Relationship.map((realtionship) => (
                      <MenuItem
                        value={realtionship.RelationshipTypeGUID}
                        key={realtionship.RelationshipTypeDescription}
                      >
                        {realtionship.RelationshipTypeDescription}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="contactinfo-type-email">Email</InputLabel>
                <Select
                  label="Email"
                  required
                  value={`${cloud9EmailinfoType}`}
                  onChange={(e) =>
                    setcloud9EmailinfoType(
                      e.target.value,
                      'cloud9_email_contact_info'
                    )
                  }
                >
                  {!contactinfoTypesError && !cloudContactinfoTypes ? (
                    <MenuItem>
                      <CircularProgress size={25} />
                    </MenuItem>
                  ) : contactinfoTypesError ? null : (
                    cloudContactinfoTypes.map((info) => (
                      <MenuItem
                        value={info.ContactInfoDescription}
                        key={info.ContactInfoDescription}
                      >
                        {info.ContactInfoDescription}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 250 }} size="small">
                <InputLabel id="contactinfo-type-phone">Phone</InputLabel>
                <Select
                  label="Phone"
                  required
                  value={`${cloud9PhoneinfoType}`}
                  onChange={(e) =>
                    setcloud9PhoneinfoType(
                      e.target.value,
                      'cloud9_phone_contact_info'
                    )
                  }
                >
                  {!contactinfoTypesError && !cloudContactinfoTypes ? (
                    <MenuItem>
                      <CircularProgress size={25} />
                    </MenuItem>
                  ) : contactinfoTypesError ? null : (
                    cloudContactinfoTypes.map((info) => (
                      <MenuItem
                        value={info.ContactInfoDescription}
                        key={info.ContactInfoDescription}
                      >
                        {info.ContactInfoDescription}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const DeleteCloud9Calendar = ({ calendarId, isOpen, onReject, onResolve }) => {
  const {
    mutate: deleteCalendar,
    isError,
    isSuccess,
    isLoading,
  } = useCloud9CalendarDelete();

  const handledeleteSubmit = () => {
    deleteCalendar(calendarId);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Calendar</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem while deleteing calendar.
          </Alert>
        )}
        {isSuccess && <Alert severity="success">Deleted successfully.</Alert>}
        Are you sure to delete calendar.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          disabled={isSuccess}
          onClick={handledeleteSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

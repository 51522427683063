import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Button,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import HookForm from '../components/HookForm';
import { LoadingButton } from '@mui/lab';
import { PlusIcon, EditIcon, DeleteIcon } from '../theme/icons';
import { useOrg } from '../../contexts/OrgProvider';
import { fireDialog } from '../components/Dialog';
import {
  useAIMappingsCreate,
  useAIMappingsEdit,
  useAIMappingsDelete,
} from '../../api/aiMappings/mutation';
import { useAiProfileShow } from '../../api/aiprofile/queries';
import { useAIModulesFunctionArgumentsList } from '../../api/aiModulesFunctionArguments/queries';
import { useCustomFields } from '../../api/customFields/queries';

export default function AIMappingsList() {
  const { id: orgId } = useOrg();
  const { agentID } = useParams();

  const [updatedAiMappingsList, setUpdatedAiMappingsList] = useState();

  const {
    data: customFields,
    isFetching: areCustomFieldsLoading,
    isError: didLoadingCustomFieldsError,
  } = useCustomFields([['organization_id[]', orgId]]);

  const { data: aiModulesShow } = useAiProfileShow(agentID);
  const {
    data: aiModulesFunctionArgumentsList,
  } = useAIModulesFunctionArgumentsList();

  const onCreate = () => {
    fireDialog((promiseProps) => AddMappings({ agentID, orgId, ...promiseProps }));
  };

  const onEditClick = (mappings) => {
    fireDialog((promiseProps) =>
      EditMappings({ mappings, orgId, aiModulesShow, ...promiseProps })
    );
  };

  const onDeleteClick = (mappingsID) => {
    fireDialog((promiseProps) => DeleteMappings({ mappingsID, ...promiseProps }));
  };

  useEffect(() => {
    if (customFields && aiModulesShow && aiModulesFunctionArgumentsList) {
      // Function to find the name by ID in a given collection
      const findNameById = (collection, id) => {
        const item = collection?.find((item) => item.id === id);
        return item ? item.name : null;
      };

      // Map over each item in aiModulesShow and update it with the names
      const newAiMappingsList = aiModulesShow?.mappings?.map((mapping) => {
        const customFieldName = findNameById(customFields, mapping?.custom_field_id);

        return {
          ...mapping,
          custom_field_name: customFieldName,
        };
      });

      // Update the state with the new aiMappingsList
      setUpdatedAiMappingsList(newAiMappingsList);
    }
  }, [customFields, aiModulesShow, aiModulesFunctionArgumentsList]);

  return (
    <Box sx={{ mt: 4 }}>
      <h6>Mappings</h6>
      <TableContainer sx={{ mt: 1, mb: 2 }}>
        <Table>
          <TableHead>
            <TableRow size="small">
              <TableCell>Function</TableCell>
              <TableCell>Argument</TableCell>
              <TableCell>Custom</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(updatedAiMappingsList) &&
              updatedAiMappingsList?.map((mappings) => (
                <TableRow key={mappings.id}>
                  <TableCell>
                    <Typography component={'span'} variant="subtitle3">
                      {mappings?.function_definition_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'span'} variant="subtitle3">
                      {mappings?.function_argument_name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography component={'span'} variant="subtitle3">
                      {mappings?.custom_field_name}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      startIcon={<EditIcon />}
                      size="small"
                      variant="text"
                      color="primary"
                      sx={{ marginRight: 1 }}
                      onClick={() => onEditClick(mappings)}
                    >
                      Edit
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      size="small"
                      variant="text"
                      color="error"
                      sx={{ marginRight: 1 }}
                      onClick={() => onDeleteClick(mappings.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button startIcon={<PlusIcon />} onClick={onCreate} sx={{ marginRight: 1 }}>
        Add New
      </Button>
    </Box>
  );
}

const AddMappings = ({ agentID, orgId, isOpen, onReject, onResolve }) => {
  const {
    mutate: createMappings,
    isLoading,
    isError,
    isSuccess,
  } = useAIMappingsCreate();
  const { data: aiModulesShow } = useAiProfileShow(agentID);

  const {
    data: aiModulesFunctionArgumentsList,
  } = useAIModulesFunctionArgumentsList();

  const {
    data: customFields,
    isFetching: areCustomFieldsLoading,
    isError: didLoadingCustomFieldsError,
  } = useCustomFields([['organization_id[]', orgId]]);

  const [selectedFunctionArgument, setSelectedFunctionArgument] = useState();
  const [selectedCustomField, setSelectedCustomField] = useState();

  const handleCreateSubmit = () => {
    createMappings({
      ai_profile_id: agentID,
      custom_field_id: selectedCustomField || '',
      function_argument_id: selectedFunctionArgument || '',
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  const defaultValues = {
    ai_profile_id: '',
    custom_field_id: '',
    function_argument_id: '',
  };

  const useFormProps = {
    defaultValues,
  };

  const extractedArguments = aiModulesShow?.ai_module?.function_definitions
    ?.map((item) => {
      return item.function_arguments.map((arg) => ({
        name: arg.name,
        id: arg.id,
        function_definit_id: item.id,
      }));
    })
    .flat();

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Create Mappings</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while creating mappings.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">Mappings created successfully.</Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <FormControl size="small">
                <InputLabel id="function_argument_id">Function Argument</InputLabel>
                <Select
                  id="function_argument_id"
                  label="Function Argument"
                  value={selectedFunctionArgument || ''}
                  onChange={(e) =>
                    setSelectedFunctionArgument(
                      e.target.value,
                      'function_argument_id'
                    )
                  }
                >
                  {extractedArguments &&
                  Array.isArray(extractedArguments) &&
                  extractedArguments.length > 0 ? (
                    extractedArguments.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{''}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl size="small">
                <InputLabel id="custom_field_id">Custom Field</InputLabel>
                <Select
                  id="custom_field_id"
                  label="Custom Field"
                  value={selectedCustomField || ''}
                  onChange={(e) =>
                    setSelectedCustomField(e.target.value, 'custom_field_id')
                  }
                >
                  {customFields &&
                    customFields?.length > 0 &&
                    customFields?.map((customField) => (
                      <MenuItem key={customField.name} value={customField.id}>
                        {customField.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const EditMappings = ({
  mappings,
  orgId,
  aiModulesShow,
  isOpen,
  onReject,
  onResolve,
}) => {
  const {
    mutate: aiMappingsedit,
    isLoading,
    isError,
    isSuccess,
  } = useAIMappingsEdit();

  const [selectedFunctionArgument, setSelectedFunctionArgument] = useState();
  const [selectedCustomField, setSelectedCustomField] = useState();

  const {
    data: customFields,
    isFetching: areCustomFieldsLoading,
    isError: didLoadingCustomFieldsError,
  } = useCustomFields([['organization_id[]', orgId]]);

  useEffect(() => {
    setSelectedFunctionArgument(mappings.function_argument_id);
    setSelectedCustomField(mappings.custom_field_id);
  }, [mappings]);

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  const defaultValues = {
    ai_profile_id: mappings.ai_profile_id,
    custom_field_id: mappings.custom_field_id,
    function_argument_id: mappings.function_argument_id,
  };

  const useFormProps = {
    defaultValues,
  };

  const handleCreateSubmit = () => {
    aiMappingsedit({
      id: mappings.id,
      ai_profile_id: mappings.ai_profile_id,
      custom_field_id: selectedCustomField || mappings.custom_field_id,
      function_argument_id:
        selectedFunctionArgument || mappings.function_argument_id,
    });
  };

  const extractedArguments = aiModulesShow?.ai_module?.function_definitions
    ?.map((item) => {
      return item.function_arguments.map((arg) => ({
        name: arg.name,
        id: arg.id,
        function_definit_id: item.id,
      }));
    })
    .flat();

  return (
    <Box>
      <Dialog open={isOpen} onClose={onReject}>
        <DialogTitle>Edit Mappings</DialogTitle>
        <HookForm useFormProps={useFormProps} onSubmit={handleCreateSubmit}>
          <DialogContent>
            {isError && (
              <Alert severity="error">
                There was a problem while updating mappings.
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">Mappings updated successfully.</Alert>
            )}
            <Box display="flex" flexDirection="column" rowGap={2}>
              <FormControl size="small">
                <InputLabel id="function_argument_id">Function Argument</InputLabel>
                <Select
                  id="function_argument_id"
                  label="Function Argument"
                  value={selectedFunctionArgument || ''}
                  onChange={(e) =>
                    setSelectedFunctionArgument(
                      e.target.value,
                      'function_argument_id'
                    )
                  }
                >
                  {extractedArguments &&
                  Array.isArray(extractedArguments) &&
                  extractedArguments.length > 0 ? (
                    extractedArguments.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{''}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl size="small">
                <InputLabel id="custom_field_id">Custom Field</InputLabel>
                <Select
                  id="custom_field_id"
                  label="Custom Field"
                  value={selectedCustomField || ''}
                  onChange={(e) =>
                    setSelectedCustomField(e.target.value, 'custom_field_id')
                  }
                >
                  {customFields &&
                    customFields?.length > 0 &&
                    customFields?.map((customField) => (
                      <MenuItem key={customField.name} value={customField.id}>
                        {customField.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="text" color="inherit" onClick={onReject}>
              Cancel
            </Button>
            <LoadingButton type="submit" loading={isLoading} disabled={isSuccess}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </HookForm>
      </Dialog>
    </Box>
  );
};

const DeleteMappings = ({ mappingsID, isOpen, onReject, onResolve }) => {
  const {
    mutate: deleteMappings,
    isError,
    isSuccess,
    isLoading,
  } = useAIMappingsDelete();

  const handledeleteSubmit = () => {
    deleteMappings(mappingsID);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Delete Mappings</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem while deleting mappings.
          </Alert>
        )}
        {isSuccess && <Alert severity="success">Deleted successfully.</Alert>}
        Are you sure to delete mappings.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          disabled={isSuccess}
          onClick={handledeleteSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

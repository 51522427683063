import React from 'react';
import { useState, useEffect } from 'react';
import {
  TableContainer,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Box,
  Grid,
  Link,
  Chip,
  Card,
  Typography,
  Alert,
  CircularProgress,
  TextField,
  MenuItem,
} from '@mui/material';
import { SettingsCard } from './_settingsPagesComponents/SettingsCard';
import { useOrg } from '../../contexts/OrgProvider';
import { fireDialog } from '../components/Dialog';
import { useCalendarsList } from '../services/calendars/queries';
import { LoadingButton } from '@mui/lab';
import { useCalendarsCreate } from '../services/calendars/mutations';
import HookForm from '../components/HookForm';
import DolphinCalendarSettingsPage from './DolphinCalendarSettingspage';

export default function DolphinSettings() {
  const { id: orgId } = useOrg();
  const { data: calendarData } = useCalendarsList([
    {
      vendor: 'dolphin',
      organization_id: orgId,
    },
  ]);
  const [isDolphinActive, setDolphinActive] = useState(null);
  const [isDolphinError, setDolphinError] = useState();
  const [isLoading, setIsLoading] = useState();

  const handleSubscribe = () => {
    fireDialog((promiseProps) => <AddDolphinCalendar {...promiseProps} />);
  };

  useEffect(() => {
    if (calendarData?.items && calendarData.items.length > 0) {
      setDolphinActive(true);
      setIsLoading(false);
    } else {
      setDolphinActive(false);
    }
  }, [calendarData]);

  return (
    <Box sx={{ display: 'flex' }}>
      <SettingsCard
        sx={{
          width: '100%',
        }}
      >
        <Grid container>
          <SettingsCard.Header>Dolphin Integration</SettingsCard.Header>
          <Grid item xs={3} m={0.5}>
            {isDolphinActive ? (
              <Chip
                label="Active"
                color="primary"
                style={{
                  backgroundColor: '#009900',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid green',
                }}
              />
            ) : (
              <Chip
                label="Inactive"
                color="primary"
                style={{
                  backgroundColor: '#dce0dd',
                  borderRadius: '4px',
                  mt: '2',
                  border: '0px solid #dce0dd',
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" sx={{ marginTop: 2 }}>
          <Grid item xs={8}>
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <>
                {isDolphinError && !isDolphinActive ? (
                  <Alert severity="warning" sx={{ mb: 2 }}>
                    Contact LeadSigma support at support@leadsigma.com or (913)
                    243-8377 to enable Dolphin.
                  </Alert>
                ) : null}

                {!isDolphinActive && !isLoading ? (
                  <Alert severity="info" sx={{ mb: 2 }}>
                    Activate our Dolphin integration to experience the seamless magic
                    of turning scheduled appointments into won leads automatically.
                    <br /> Let our AI digital assistant take the lead in scheduling
                    appointments directly on your calendar.
                  </Alert>
                ) : null}
              </>
            )}
          </Grid>
          {isDolphinActive && !isLoading ? (
            <Grid item xs={8}>
              <DolphinCalendarSettingsPage calendarData={calendarData} />
            </Grid>
          ) : null}
          <Grid item>
            <Card
              sx={{
                minWidth: 250,
                minHeight: 100,
                display: 'flex',
                ml: 4,
              }}
              style={{ backgroundColor: '#f2f1ed' }}
              elevation={0}
            >
              <Grid item ml={2} mt={2} paddingBottom={2}>
                <Typography variant="body1" sx={{ width: 200, minWidth: '100%' }}>
                  Question about this integration? Check out here
                </Typography>
                <Link
                  target="blank"
                  href="https://sigmaleads.atlassian.net/wiki/spaces/LCS/pages/1634598918/Dolphin"
                >
                  Support Documentation
                </Link>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {!isDolphinActive ? (
          <Button color="inherit" onClick={handleSubscribe}>
            Enable
          </Button>
        ) : null}

        <TableContainer sx={{ mb: 2 }}></TableContainer>
      </SettingsCard>
    </Box>
  );
}

const AddDolphinCalendar = ({ isOpen, onReject, onResolve }) => {
  const { id: orgId } = useOrg();
  const [Calendarname, setCalendarname] = useState('');
  const [endpointId, setendpointId] = useState('');

  const {
    mutate: createCalendar,
    isError,
    isLoading,
    isSuccess,
  } = useCalendarsCreate();

  const defaultValues = {
    name: '',
    timeZone: '',
    endpointId: '',
  };

  const useFormProps = {
    defaultValues,
  };

  const handleCreateCalendar = (formData) => {
    createCalendar({
      name: Calendarname,
      timezone: formData?.timeZone,
      organization_id: orgId,
      vendor: 'dolphin',
      config_items: [
        {
          name: 'dolphin_endpoint_id',
          value: endpointId,
        },
      ],
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  const possibleTimezones = [
    'Pacific/Honolulu',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Puerto_Rico',
  ];

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Create Dolphin Calendar</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleCreateCalendar}>
        <DialogContent>
          {isSuccess && (
            <Alert severity="success">Calender created successfully.</Alert>
          )}
          {isError && (
            <Alert severity="error">
              There was a problem while creating calendar.
            </Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <TextField
              label="Calendar Name"
              variant="outlined"
              name="name"
              fullWidth
              value={Calendarname || ''}
              onChange={(e) => setCalendarname(e.target.value)}
            />
            <HookForm.Select name="timeZone" label="Time Zone">
              {possibleTimezones.map((zone) => (
                <MenuItem value={zone} key={zone}>
                  {zone}
                </MenuItem>
              ))}
            </HookForm.Select>
            <TextField
              label="Endpoint ID"
              variant="outlined"
              name="endpointId"
              fullWidth
              value={endpointId || ''}
              onChange={(e) => setendpointId(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton type="submit" disabled={isSuccess} loading={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

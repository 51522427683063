import { React, useState, useEffect } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  Alert,
  FormControl,
  InputLabel,
  Select,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import HookForm from '../components/HookForm';
import { PlusIcon, EditIcon, DeleteIcon } from '../theme/icons';
import { fireDialog } from '../components/Dialog';
import { useOrg } from '../../contexts/OrgProvider';
import {
  useCalendarsCreate,
  useCalendarsEdit,
  useCalendarsDelete,
} from '../services/calendars/mutations';
import { useDolphinAppointmentTypes } from '../services/dolphinAppointmentTypes/queries';
import { useDolphinPhoneTypes } from '../services/dolphinPhoneTypes/queries';
import { useDolphinProviders } from '../services/dolphinProviders/queries';
import { useDolphinLocations } from '../services/dolphinLocations/queries';

export default function DolphinCalendarSettingsPage({ calendarData }) {
  const { id: orgId } = useOrg();

  const onAddClick = () => {
    fireDialog((promiseProps) => (
      <AddDolphinCalendar {...promiseProps} orgId={orgId} />
    ));
  };

  const onEditClick = (calendar) => {
    fireDialog((promiseProps) => (
      <EditDolphinCalendar {...promiseProps} calendarData={calendar} />
    ));
  };

  const onDeleteClick = (calendarId) => {
    fireDialog((promiseProps) => (
      <DeleteCloud9Calendar {...promiseProps} calendarId={calendarId} />
    ));
  };

  return (
    <Box sx={{ mt: 2 }}>
      <h6>Dolphin Calendars</h6>
      <TableContainer sx={{ mt: 1, mb: 2 }}>
        <Table style={{ width: 500 }}>
          <TableHead>
            <TableRow size="small">
              <TableCell align="left">Name</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {calendarData?.items.map((calendar) => (
              <TableRow key={calendar.id}>
                <TableCell align="left">
                  <Typography component={'span'} variant="subtitle3">
                    {calendar.name}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Button
                    startIcon={<EditIcon />}
                    size="small"
                    variant="text"
                    color="primary"
                    sx={{ marginRight: 1 }}
                    onClick={() => onEditClick(calendar)}
                  >
                    Edit
                  </Button>
                  <Button
                    startIcon={<DeleteIcon />}
                    size="small"
                    variant="text"
                    color="error"
                    sx={{ marginRight: 1 }}
                    onClick={() => onDeleteClick(calendar.id)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        startIcon={<PlusIcon />}
        variant="contained"
        color="primary"
        onClick={onAddClick}
      >
        Add New
      </Button>
    </Box>
  );
}

const AddDolphinCalendar = ({ orgId, isOpen, onReject, onResolve }) => {
  const {
    mutate: createCalendar,
    isError,
    isLoading,
    isSuccess,
  } = useCalendarsCreate();

  const [setCalendarname, Calendarname] = useState('');
  const [endpointId, setendpointId] = useState('');

  const defaultValues = {
    name: '',
    timeZone: '',
    endpointId: '',
  };
  const useFormProps = {
    defaultValues,
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  const possibleTimezones = [
    'Pacific/Honolulu',
    'America/Los_Angeles',
    'America/Phoenix',
    'America/Denver',
    'America/Chicago',
    'America/New_York',
    'America/Puerto_Rico',
  ];

  const handleCreateCalendar = (formData) => {
    createCalendar({
      name: setCalendarname,
      timezone: formData?.timeZone,
      organization_id: orgId,
      vendor: 'dolphin',
      config_items: [
        {
          name: 'dolphin_endpoint_id',
          value: endpointId,
        },
      ],
    });
  };

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Create Dolphin Calendar</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleCreateCalendar}>
        <DialogContent>
          {isSuccess && (
            <Alert severity="success">Calender created successfully.</Alert>
          )}
          {isError && (
            <Alert severity="error">
              There was a problem while creating calendar.
            </Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <TextField
              label="Calendar Name"
              variant="outlined"
              name="name"
              fullWidth
              value={setCalendarname || ''}
              onChange={(e) => Calendarname(e.target.value)}
            />
            <HookForm.Select name="timeZone" label="Time Zone">
              {possibleTimezones.map((zone) => (
                <MenuItem value={zone} key={zone}>
                  {zone}
                </MenuItem>
              ))}
            </HookForm.Select>
            <TextField
              label="Endpoint ID"
              variant="outlined"
              name="endpointId"
              fullWidth
              value={endpointId || ''}
              onChange={(e) => setendpointId(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton type="submit" disabled={isSuccess} loading={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

const EditDolphinCalendar = ({ calendarData, isOpen, onReject, onResolve }) => {
  const { mutate: calendarEdit, isLoading, isError, isSuccess } = useCalendarsEdit();
  const {
    data: appointmentTypes,
    isError: isAppointmentError,
    isLoading: isAppointmentLoading,
  } = useDolphinAppointmentTypes({
    calendar_id: calendarData?.id,
  });
  const {
    data: phoneTypes,
    isError: isPhoneError,
    isLoading: isPhoneLoading,
  } = useDolphinPhoneTypes({
    calendar_id: calendarData?.id,
  });
  const {
    data: providers,
    isError: isProviderError,
    isLoading: isProviderLoading,
  } = useDolphinProviders({
    calendar_id: calendarData?.id,
  });

  const {
    data: locations,
    isError: isLocationError,
    isLoading: isLocationLoading,
  } = useDolphinLocations({
    calendar_id: calendarData?.id,
  });

  const [setCalendarname, Calendarname] = useState(calendarData?.name);
  const [daysOut, setDaysOut] = useState();
  const [endpointId, setEndpointId] = useState();
  const [appointmentType, setAppointmentType] = useState();
  const [phoneType, setPhoneType] = useState();
  const [provider, setProvider] = useState();
  const [location, setLocation] = useState();
  const [appointmentTypeAge18, setAppointmentTypeAge18] = useState();
  const [appointmentTypeAge12, setAppointmentTypeAge12] = useState();

  useEffect(() => {
    const item = calendarData?.config_items?.find(
      (item) => item?.name === 'days_out'
    );
    if (item) {
      setDaysOut(item?.value);
    }
  }, [calendarData]);

  const defaultValues = {
    name: '',
    timeZone: '',
    daysOut: '',
    endpointId: '',
    appointmentType: '',
    phoneType: '',
    provider: '',
    location: '',
    appointmentTypeAge18: '',
    appointmentTypeAge12: '',
  };

  const useFormProps = {
    defaultValues,
  };

  useEffect(() => {
    const setDefaultValue = (itemName, setterFunction) => {
      if (calendarData?.config_items) {
        const item = calendarData?.config_items?.find(
          (item) => item?.name === itemName
        );
        if (setterFunction) {
          setterFunction(item?.value);
        }
      }
    };

    setDefaultValue('days_out', setDaysOut);
    setDefaultValue('dolphin_endpoint_id', setEndpointId);
    setDefaultValue('dolphin_appointment_type_id', setAppointmentType);
    setDefaultValue('dolphin_phone_type_id', setPhoneType);
    setDefaultValue('dolphin_provider_id', setProvider);
    setDefaultValue('dolphin_location_id', setLocation);
    setDefaultValue('dolphin_appointment_type_id_18', setAppointmentTypeAge18);
    setDefaultValue('dolphin_appointment_type_id_12', setAppointmentTypeAge12);
  }, [calendarData]);

  const handleEditCalendar = (formData) => {
    calendarEdit({
      id: calendarData.id,
      name: setCalendarname,
      timezone: calendarData?.timeZone,
      config_items: [
        {
          name: 'days_out',
          value: daysOut,
        },
        {
          name: 'dolphin_endpoint_id',
          value: endpointId,
        },
        {
          name: 'dolphin_appointment_type_id',
          value: appointmentType,
        },
        {
          name: 'dolphin_appointment_type_id_18',
          value: appointmentTypeAge18,
        },
        {
          name: 'dolphin_appointment_type_id_12',
          value: appointmentTypeAge12,
        },
        {
          name: 'dolphin_phone_type_id',
          value: phoneType,
        },
        {
          name: 'dolphin_provider_id',
          value: provider,
        },
        {
          name: 'dolphin_location_id',
          value: location,
        },
      ],
    });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Edit Dolphin Calendar</DialogTitle>
      <HookForm useFormProps={useFormProps} onSubmit={handleEditCalendar}>
        <DialogContent>
          {isSuccess && (
            <Alert severity="success">Calendar edited successfully.</Alert>
          )}
          {isError && (
            <Alert severity="error">There was a problem while editing.</Alert>
          )}
          <Box display="flex" flexDirection="column" rowGap={2}>
            <TextField
              label="Calendar Name"
              variant="outlined"
              name="name"
              fullWidth
              value={setCalendarname || ''}
              onChange={(e) => Calendarname(e.target.value)}
            />
            <TextField
              label="Days Out"
              type="number"
              required
              fullWidth
              defaultValue={daysOut || ''}
              onChange={(e) => setDaysOut(e.target.value)}
            />
            <FormControl sx={{ minWidth: 250 }} size="small">
              <InputLabel id="provider">Provider</InputLabel>
              <Select
                label="Provider"
                required
                value={`${provider}`}
                onChange={(e) => setProvider(e.target.value, 'dolphin_provider_id')}
              >
                {!providers && !isProviderError ? (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                ) : isProviderError ? null : Array.isArray(providers) ? (
                  providers.map((provider) => (
                    <MenuItem value={provider.Id} key={provider.Id}>
                      {provider.FirstName} {provider.LastName}
                    </MenuItem>
                  ))
                ) : null}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 250 }} size="small">
              <InputLabel id="phone-types">Phone Types</InputLabel>
              <Select
                label="Phone Types"
                required
                value={`${phoneType}`}
                onChange={(e) =>
                  setPhoneType(e.target.value, 'dolphin_phone_type_id')
                }
              >
                {!phoneTypes && !isPhoneError ? (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                ) : isPhoneError ? null : Array.isArray(phoneTypes) ? (
                  phoneTypes.map((phoneType) => (
                    <MenuItem value={phoneType.Id} key={phoneType.Id}>
                      {phoneType.Description}
                    </MenuItem>
                  ))
                ) : null}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 250 }} size="small">
              <InputLabel id="appointment-type">Appointment Types</InputLabel>
              <Select
                label="Appointment Types"
                required
                value={`${appointmentType}`}
                onChange={(e) =>
                  setAppointmentType(e.target.value, 'dolphin_appointment_type_id')
                }
              >
                {!appointmentTypes && !isAppointmentError ? (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                ) : isAppointmentError ? null : Array.isArray(appointmentTypes) ? (
                  appointmentTypes.map((appointmentType) => (
                    <MenuItem value={appointmentType.Id} key={appointmentType.Id}>
                      {appointmentType.Description}
                    </MenuItem>
                  ))
                ) : null}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 250 }} size="small">
              <InputLabel id="appointment-type-12-18">
                Appointment Type for Adolescents
              </InputLabel>
              <Select
                label="Appointment Type for Adolescents"
                required
                value={`${appointmentTypeAge18}`}
                onChange={(e) =>
                  setAppointmentTypeAge18(
                    e.target.value,
                    'dolphin_appointment_type_id_18'
                  )
                }
              >
                {!appointmentTypes && !isAppointmentError ? (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                ) : isAppointmentError ? null : Array.isArray(appointmentTypes) ? (
                  appointmentTypes.map((appointmentType) => (
                    <MenuItem value={appointmentType.Id} key={appointmentType.Id}>
                      {appointmentType.Description}
                    </MenuItem>
                  ))
                ) : null}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 250 }} size="small">
              <InputLabel id="appointment-type-child">
                Appointment Type Child
              </InputLabel>
              <Select
                label="Appointment Type Child"
                required
                value={`${appointmentTypeAge12}`}
                onChange={(e) =>
                  setAppointmentTypeAge12(
                    e.target.value,
                    'dolphin_appointment_type_id_12'
                  )
                }
              >
                {!appointmentTypes && !isAppointmentError ? (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                ) : isAppointmentError ? null : Array.isArray(appointmentTypes) ? (
                  appointmentTypes.map((appointmentType) => (
                    <MenuItem value={appointmentType.Id} key={appointmentType.Id}>
                      {appointmentType.Description}
                    </MenuItem>
                  ))
                ) : null}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 250 }} size="small">
              <InputLabel id="location">Location</InputLabel>
              <Select
                label="Location"
                required
                value={`${location}`}
                onChange={(e) => setLocation(e.target.value, 'dolphin_location_id')}
              >
                {!locations && !isLocationError ? (
                  <MenuItem>
                    <CircularProgress size={20} />
                  </MenuItem>
                ) : isLocationError ? null : Array.isArray(locations) ? (
                  locations.map((location) => (
                    <MenuItem value={location.Id} key={location.Id}>
                      {location.Name}
                    </MenuItem>
                  ))
                ) : null}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onReject}>
            Cancel
          </Button>
          <LoadingButton type="submit" disabled={isSuccess} loading={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </HookForm>
    </Dialog>
  );
};

const DeleteCloud9Calendar = ({ calendarId, isOpen, onReject, onResolve }) => {
  const {
    mutate: deleteCalendar,
    isError,
    isSuccess,
    isLoading,
  } = useCalendarsDelete();

  const handledeleteSubmit = () => {
    deleteCalendar(calendarId);
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setTimeout(() => {
        onResolve();
      }, 1000);
    }
  }, [isError, isSuccess]);

  return (
    <Dialog open={isOpen} onClose={onReject}>
      <DialogTitle>Calendar</DialogTitle>
      <DialogContent>
        {isError && (
          <Alert severity="error">
            There was a problem while deleteing calendar.
          </Alert>
        )}
        {isSuccess && <Alert severity="success">Deleted successfully.</Alert>}
        Are you sure to delete calendar.
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="inherit" onClick={onReject}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          disabled={isSuccess}
          onClick={handledeleteSubmit}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

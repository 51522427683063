import { SettingsCard } from './_settingsPagesComponents/SettingsCard';

import { Box, Typography, Card, CardMedia, CardContent, Grid } from '@mui/material';

import { Link } from 'react-router-dom';

import Nylas from '../../assets/images/Nylas_logo.svg';
import NextHealth from '../../assets/images/NexHealth_logo.svg';
import Google from '../../assets/images/Google_ads-icon.svg';
import Cloud9 from '../../assets/images/Cloud9_logo.svg';
import Dolphin from '../../assets/images/Dolphin_logo.svg';

const IntegrationsPage = () => {
  return (
    <Box sx={{ height: '100%', flexGrow: 1 }}>
      <SettingsCard
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SettingsCard.Header>Integrations</SettingsCard.Header>

        <Grid container spacing={1} mt={1}>
          <Grid item xs={12} sm={6}>
            <Link to="/settings/integrations/nexhealth">
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  boxShadow: 0,
                }}
              >
                <CardMedia
                  component="img"
                  image={NextHealth}
                  sx={{
                    height: 100,
                    backgroundPosition: 'center',
                    objectFit: 'contain',
                    width: '8rem',
                    mt: 2,
                    py: '1rem',
                    border: '0.1px solid #d9d9d9',
                    borderRadius: 2,
                  }}
                />
                <Grid item mt={0}>
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>
                      NexHealth
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ minWidth: '100%', display: 'flex' }}
                    >
                      Connect NexHealth for orthodontic and dental practice
                      management integration
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link to="/settings/integrations/nylas">
              <Card sx={{ display: 'flex', flexDirection: 'row', boxShadow: 0 }}>
                <CardMedia
                  component="img"
                  image={Nylas}
                  sx={{
                    height: 100,
                    backgroundPosition: 'center',
                    objectFit: 'contain',
                    width: '7rem',
                    mt: 2,
                    py: '1rem',
                    border: '0.1px solid #d9d9d9',
                    borderRadius: 2,
                  }}
                />
                <Grid mt={0}>
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>
                      Nylas
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ minWidth: '100%', display: 'flex' }}
                    >
                      Connect Nylas for Gmail, Outlook calendar and more to LeadSigma
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link to="/settings/integrations/google">
              <Card sx={{ display: 'flex', flexDirection: 'row', boxShadow: 0 }}>
                <CardMedia
                  component="img"
                  image={Google}
                  sx={{
                    height: 100,
                    backgroundPosition: 'center',
                    objectFit: 'contain',
                    width: '6rem',
                    mt: 2,
                    py: '1rem',
                    border: '0.1px solid #d9d9d9',
                    borderRadius: 2,
                  }}
                />
                <Grid mt={0}>
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>
                      Google Ads
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ minWidth: '100%', display: 'flex' }}
                    >
                      See your call and form data as conversions
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link to="/settings/integrations/cloud9">
              <Card sx={{ display: 'flex', flexDirection: 'row', boxShadow: 0 }}>
                <CardMedia
                  component="img"
                  image={Cloud9}
                  sx={{
                    height: 100,
                    backgroundPosition: 'center',
                    objectFit: 'contain',
                    width: '7rem',
                    mt: 2,
                    py: '1rem',
                    border: '0.1px solid #d9d9d9',
                    borderRadius: 2,
                  }}
                />
                <Grid mt={0}>
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>
                      Cloud9
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ minWidth: '100%', display: 'flex' }}
                    >
                      Connect Cloud9 for orthodontic practice management integration
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link to="/settings/integrations/dolphin">
              <Card sx={{ display: 'flex', flexDirection: 'row', boxShadow: 0 }}>
                <CardMedia
                  component="img"
                  image={Dolphin}
                  sx={{
                    height: 100,
                    backgroundPosition: 'center',
                    objectFit: 'contain',
                    width: '7rem',
                    mt: 2,
                    py: '1rem',
                    border: '0.1px solid #d9d9d9',
                    borderRadius: 2,
                  }}
                />
                <Grid mt={0}>
                  <CardContent>
                    <Typography variant="h6" sx={{ color: 'black' }}>
                      Dolphin
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ minWidth: '100%', display: 'flex' }}
                    >
                      Connect Dolphin for orthodontic practice management integration
                    </Typography>
                  </CardContent>
                </Grid>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </SettingsCard>
    </Box>
  );
};
export default IntegrationsPage;

import { useQuery } from 'react-query';
import { queryKeyFactory } from '../queryClientConfig';
import DolphinProviders from './dolphin';

export const dolphinQueryKeys = queryKeyFactory('dolphinProviders');

export const useDolphinProviders = (filters, options) => {
  return useQuery(
    dolphinQueryKeys.list(filters),
    () => DolphinProviders.list(filters),
    {
      staleTime: 0,
      retry: 0,
      ...options,
    }
  );
};
